import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
const axios = require('axios')
import Layout from '../../../components/layout'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap'
import header from '../../../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../../../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import LinkBack from '../../../components/link-back'

class EmailSignUpTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      zone: 'Bolton',
      alertday: 'today',
      postcode: null,
      email: null,
      year_of_birth: null,
      error: false,
      message: null,
      success: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    var ts = Math.round(new Date().getTime() / 1000)
    axios(
      `https://cleanairgm.com/.netlify/functions/subscribe?zone=${this.state.zone}&alertmode=Email&email=${this.email}&alertday=${this.state.alertday}&postcode=${this.state.postcode}&year_of_birth=${this.state.year_of_birth}&ts=${ts}`
    ).then(response => {
      if (response.data.status === 400) {
        this.setState({ error: true, message: response.data.message })
        return
      }

      if (response.data.status === 200) {
        this.setState({ error: false, success: true })
        return
      }

      this.setState({
        error: true,
        message: 'An error has occurred, please try again later.',
      })
    })
  }

  render() {
    const post = get(this.props, 'data.contentfulBasicPage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`Subscribe to email alerts | ${siteTitle}`} />

          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />{' '}
            <h1>Subscribe to email alerts</h1>
          </div>

          <Container className="news mb-5">
            <LinkBack
              to={'/data-hub/forecast-and-alerts'}
              title={'back to Forecast and alerts'}
            />

            <Row>
              <Col>
                <p>Receive air pollution email alerts by signing up below.</p>

                <p>
                  You’ll receive an email if the air pollution is predicted to
                  be moderate or above on the Daily Air Quality Index scale.
                </p>

                <p>
                  You can choose whether you want to receive email alerts for
                  the day ahead (which will be sent at approximately 7am) by
                  choosing ‘same day’, or the next day (which will send an alert
                  at approximately 7pm) by choosing ‘next day’.
                </p>
              </Col>
            </Row>
          </Container>

          <div className="subscribe-form pt-5 pb-5 mt-5">
            <Container>
              {!this.state.success && (
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="zone">Area *</Label>
                    <Input
                      type="select"
                      name="zone"
                      id="zone"
                      value={this.state.zone}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option>Bolton</option>
                      <option>Bury</option>
                      <option>Manchester</option>
                      <option>Oldham</option>
                      <option>Rochdale</option>
                      <option>Salford</option>
                      <option>Stockport</option>
                      <option>Tameside</option>
                      <option>Trafford</option>
                      <option>Wigan</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="alertday">Alert day *</Label>
                    <Input
                      type="select"
                      name="alertday"
                      id="alertday"
                      value={this.state.alertday}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="today">Same day</option>
                      <option value="tomorrow">Next day</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="year_of_birth">Year of birth</Label>
                    <Input
                      max="2018"
                      min="1900"
                      type="number"
                      name="year_of_birth"
                      id="year_of_birth"
                      maxlength="4"
                      minlength="4"
                      value={this.state.year_of_birth}
                      onChange={this.handleInputChange}
                    ></Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="postcode">Postcode</Label>
                    <Input
                      type="text"
                      name="postcode"
                      id="postcode"
                      value={this.state.postcode}
                      onChange={this.handleInputChange}
                    ></Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="email">Email *</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      required
                    ></Input>
                  </FormGroup>
                  {this.state.error && (
                    <Alert color="danger">{this.state.message}</Alert>
                  )}
                  <button className="btn btn-content" type="submit">
                    Submit
                  </button>
                </Form>
              )}
              {this.state.success && (
                <div>
                  <h2>Success</h2>
                  <p>You have successfully subscribed</p>
                </div>
              )}
              <div className="pt-5 mt-5">
                <p>
                  By subscribing you are agreeing to our{' '}
                  <Link to="/forecasting-alerts-terms-and-conditions">
                    terms and conditions
                  </Link>
                  .
                </p>

                <p>
                  You can unsubscribe at anytime by going to our{' '}
                  <Link to="/data-hub/forecast-and-alerts/unsubscribe">
                    unsubscribe page
                  </Link>
                  .{' '}
                </p>

                <p>
                  The information you provide on this form will be used for the
                  purpose of providing you with air quality alerts by your
                  chosen method (either email or text message). It will also be
                  used for evaluation of the take up of the air quality alerts
                  scheme.
                </p>
                <p>
                  Transport for Greater Manchester (TfGM) process your personal
                  data for the purpose of providing you with air quality alerts
                  with your consent. We process your postcode and year of birth
                  for the purpose of evaluating the take up of the scheme, and
                  this is necessary for the performance of a task in the public
                  interest, namely fulfilling our duty under s99 of the Local
                  Transport Act 2008 to improve the environmental wellbeing of
                  Greater Manchester
                </p>
                <p>
                  Your personal data will be held until you opt out of receiving
                  alerts, when it will be deleted. If we end the scheme we will
                  delete your data on closure of the scheme.
                </p>
                <p>
                  Information on exercising your rights under data protection
                  law as well as more information about how we process your
                  personal data can be found in our Privacy Policy on the TfGM
                  website at.
                  <a href="http://tfgm.com/privacy-policy">
                    http://tfgm.com/privacy-policy
                  </a>
                </p>
                <p>
                  You can contact the Data Protection Officer by emailing{' '}
                  <a href="mailto:data.protection@tfgm.com">
                    data.protection@tfgm.com
                  </a>
                  .
                </p>
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    )
  }
}

export default EmailSignUpTemplate

export const pageQuery = graphql`
  query EmailSignUpPageBySlug {
    site {
      siteMetadata {
        title
      }
    }
  }
`
